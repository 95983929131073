import Breadcrumbs from "@/router/breadcrumbs";
import _ from "lodash";
import Categories from "@/router/categories";
import Collections from "@/router/collections";
import Sizes from "@/router/sizes";

export default {
    data() {
        return {
            route: this.$route,
            prevRoute: null,
            breadcrumbs: Breadcrumbs,
            params: this.$route.params,
        };
    },

    computed: {
        hasBack() {
            if(this.$router.options.history.location === '/') {
                return false;
            }

            return Boolean(this.$router.options.history.state.back);
        },

        category() {
            if(!('category' in this.$route.params)) {
                return null;
            }

            return _.find(Categories, (_category) => {
                return _category.slug === this.$route.params.category;
            });
        },

        collection() {
            if(!('collection' in this.$route.params)) {
                return null;
            }

            return (_.find(Collections, (_collection) => {
                return _collection.slug === this.$route.params.collection;
            }));
        },

        size() {
            if(!('size' in this.$route.params)) {
                return null;
            }

            return (_.find(Sizes, (_size) => {
                return _size.slug === this.$route.params.size;
            }));
        },

        cupSize() {
            if(!('cupSize' in this.$route.params)) {
                return null;
            }

            return (_.find(Sizes, (_cupSize) => {
                return _cupSize.cupSizes.includes(this.$route.params.cupSize);
            }));
        },

        backUrl() {
            if(this.size) {
                let url = {
                    name: 'category',
                    category: this.category.slug
                }

                if(this.cupSize) {
                    url = {
                        name: 'size',
                        params: {
                            category: this.category.slug,
                            size: this.size.slug,
                        }
                    }

                    if(this.isProductPage) {
                        url = {
                            name: 'cupSize',
                            params: {
                                category: this.category.slug,
                                size: this.size.slug,
                                cupSize: this.$route.params.cupSize,
                            }
                        }
                    }
                }

                return url;
            }

            if('product' in this.$route.params && 'category' in this.$route.params && !('collection' in this.$route.params)) {
                return {
                    name: 'category',
                    params: {
                        category: this.category.slug
                    }
                }
            }

            if('product' in this.$route.params) {
                return {
                    name: 'products',
                    params: {
                        category: this.category.slug
                    }
                }
            }

            if(this.collection) {
                return {
                    name: 'category',
                    params: {
                        category: this.category.slug
                    }
                }
            }

            return {name: 'home'};
        },

        isProductPage() {
            return 'product' in this.$route.params;
        }
    },
}
