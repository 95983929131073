import BraProductsPage from "@/pages/BraStyles/ProductsPage";

export default [
    {
        name: 'The Collections',
        slug: 'collections',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/categories/category_collections_old.jpg',
        breadcrumb: {
            label: 'Collections',
        },
        renderers: {
            products: null,
            product: null,
        },
    },
    {
        name: 'Bra Styles',
        slug: 'bra_styles',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/categories/category_bra_styles_old.jpg',
        breadcrumb: {
            label: 'Bra Styles',
        },
        renderers: {
            products: BraProductsPage,
            product: null,
        },
    },
    {
        name: 'Shop By Size',
        slug: 'shop_by_size',
        image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/categories/category_shop_by_size.jpg',
        breadcrumb: {
            label: 'Shop by size',
        },
        renderers: {
            products: null,
            product: null,
        },
    },
    // {
    //     name: 'Most Wanted Bras',
    //     slug: 'most_wanted',
    //     image: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/categories/category_most_wanted.jpg',
    //     breadcrumb: {
    //         label: 'Most Wanted Bras',
    //     },
    //     renderers: {
    //         products: null,
    //         product: null,
    //     },
    // }
]
