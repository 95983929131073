<template>
    <div class="p-14 flex-shrink-0">
        <div class="flex space-x-9 text-3xl border-b-2 border-navborder font-benton-regular pb-10">
            <router-link class="flex items-center justify-center font-benton-medium"
                         v-if="backUrl"
                         :to="backUrl"
            >
                <div class="mr-3">
                    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30253 11.2626L13.1333 19.8174L11.4456 21.5596L0.87182 11.3162L11.4188 0.439569L13.1602 2.12813L4.30253 11.2626Z" fill="#221D1D"/>
                    </svg>
                </div>
                <p>Back</p>
            </router-link>

            <div class="flex items-center space-x-3">
                <router-link v-if="hasBack" :to="{name: 'home'}">
                    Home
                </router-link>

                <span>/</span>

                <template v-if="category">
                    <router-link v-if="collection || (category && !collection && route.name !== 'category')" :to="{ name: 'category', params: { category: category.slug}}">
                        {{ category.breadcrumb.label }}
                    </router-link>

                    <p class="text-navgray" v-else>{{category.breadcrumb.label}}</p>
                </template>

                <template v-if="collection">
                    <span>/</span>
                    <router-link v-if="isProductPage" :to="{ name: 'products', params: { category: category.slug, collection: collection.slug}}">
                        {{ collection.breadcrumb.label }}
                    </router-link>
                    <p class="text-navgray" v-else>{{collection.breadcrumb.label}}</p>
                </template>

                <template v-if="size">
                    <span>/</span>
                    <router-link v-if="cupSize" :to="{
                        name: 'size',
                        params: {
                            category: category.slug,
                            size: size.slug
                        }
                    }">
                        Choose cup size
                    </router-link>
                    <p class="text-navgray" v-else>Choose cup size</p>
                </template>

                <template v-if="cupSize">
                    <span>/</span>
                    <router-link v-if="isProductPage" :to="{
                        name: 'cupSize',
                        params: {
                            category: category.slug,
                            size: size.slug,
                            cupSize: route.params.cupSize,
                        }
                    }">
                        {{route.params.cupSize}}
                    </router-link>
                    <p class="text-navgray" v-else>{{route.params.cupSize}}</p>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
import BreadcrumbsMixin from "@/components/BreadcrumbsMixin";

export default {
    name: "Breadcrumbs",

    mixins: [
        BreadcrumbsMixin,
    ],
}
</script>
