/**
 * Note:
 * sizes: In-store sizes
 * moreOnline: Additional Online sizes
 * selectStores & online are not used
 */

export default [
    // very sexy
    {
        name: 'Bombshell Add-2-Cup Push-Up',
        sizedProductName: [
            'Bombshell Add-2-Cup', 'Push-Up',
        ],
        alternativeName: [
            'Bombshell',
            'Add-2-Cup Push-Up',
        ],
        slug: 'vsxy_bombshell',
        selectStores: false,
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 1,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 2,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell/poster.jpg',
        },
        spec: [
            'Add-2-cup push-up',
            'Plush padding',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '36B',
                    '36C',
                    '36D',
                    '38C',
                    '38D',
                ]
            ],
            abbreviated: [
                [
                    '32A-D',
                    '34A-D',
                    '36B-D',
                    '38C-D',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-D',
                    '32AA',
                    '34AA,',
                ],
                [
                    '34DD',
                    '36AA-A',
                    '36DD-DDD',
                    '38DD',
                    '38DDD'
                ]
            ]
        }
    },
    {
        name: 'Push-Up',
        slug: 'vsxy_push_up',
        selectStores: false,
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 2,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 1,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/vs_push_up/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/vs_push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/vs_push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/vs_push_up/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Add-1-cup push-up',
            'Plush padding',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DD',
                    '34B-DDD',
                    '36B-DDD,',
                ],[
                    '38B-DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32A',
                    '32DDD,',
                ],
                [
                    '32G',
                    '34A',
                    '34G',
                    '36A',
                    '38DDD,',
                ],
                [
                    '40C-DDD',
                    '42C-DD',
                ]
            ]
        }
    },
    // {
    //     name: 'Love',
    //     alternativeName: [
    //         'PUSH-UP',
    //     ],
    //     slug: 'vsxy_love',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'very_sexy',
    //             label: 'LOVE BY VICTORIA',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'push_up',
    //             label: 'PUSH-UP',
    //             order: 3,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/love/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/love/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/love/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/love/poster.jpg',
    //     },
    //     spec: [
    //         'Natural uplift',
    //         'Cushioned underwire',
    //         'Elongated cup',
    //         'Ballet-inspired back',
    //     ],
    //     sizes: {
    //     primary: [
    //     [
    //         'XS',
    //         'S',
    //         'SDD',
    //         'M',
    //         'MDD',
    //         'L',
    //         'LDD',
    //         'XL',
    //         '32A',
    //         '32B',
    //         '32C',
    //         '32D',
    //         '32DD (E)',
    //         '32DDD (F)',
    //         '34A',
    //         '34B',
    //         '34C',
    //         '34D',
    //         '34DD (E)',
    //         '34DDD (F)',
    //         '36B',
    //         '36C',
    //         '36D',
    //         '36DD (E)',
    //         '36DDD (F)',
    //         '38B',
    //         '38C',
    //         '38D',
    //         '38DD (E)',
    //         '38DDD (F)',
    //     ]
    // ],
    //         abbreviated: [
    //             [
    //                 '32B-DDD',
    //                 '34B-DDD',
    //                 '36B-DDD',
    //                 '38B-DD',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [],
    //         moreOnline: [
    //             [
    //                 '32-34A',
    //                 '38DDD',
    //             ]
    //         ]
    //     }
    // },
    {
        name: 'So Obsessed Push-Up',
        sizedProductName: ['So Obsessed Push-Up'],
        alternativeName: [
            'So Obsessed',
            'PUSH-UP'
        ],
        slug: 'vsxy_so_obsessed',
        selectStores: false,
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 3,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed/poster.jpg',
        },
        spec: [
            'Foam-encased underwire cups',
            'Adds 1 \u00BD cups',
            'Smoothing sides for a seamless look',
            'Fully adjustable straps',
            'VS logo charm in front'
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',

                    '38C',
                    '38D',
                ]
            ],
            abbreviated: [
                [
                    '32A-D',
                    '34A-DD',
                    '36B-DD',
                    '38C-D',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32AA',
                    '34AA,',
                ],
                [
                    '36AA-A',
                    '38DD'
                ]
            ]
        }
    },
    {
        name: 'So Obsessed Wireless Push-Up',
        sizedProductName: ['So Obsessed Wireless', 'Push-Up'],
        alternativeName: [
            'So Obsessed',
            'Wireless Push-Up'
        ],
        slug: 'vsxy_so_obsessed_wireless',
        selectStores: false,
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 4,
            },
            {
                slug: 'wireless',
                label: 'WIRELESS',
                order: 1,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed_wireless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed_wireless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed_wireless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/so_obsessed_wireless/poster.jpg',
        },
        spec: [
            'Memory fit push-up padding',
            'Adds 1 \u00BD cups',
            'Wireless design',
            'VS logo charm',
            'One piece of fabric from cup to wing',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32A-D',
                    '34A-DDD',
                    '36B-DDD,',
                ],[
                    '38B-DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32AA',
                    '32DD-DDD',
                    '34AA,',
                ],
                [
                    '36AA-A',
                    '38DDD',
                    '40C-DDD'
                ]
            ]
        }
    },
    {
        name: 'Strapless',
        slug: 'vsxy_strapless',
        selectStores: false,
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 1,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/strapless/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Plush push-up padding',
            'Patented non-slip band',
            'Removable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',

                    '38C',
                    '38D',
                ]
            ],
            abbreviated: [
                [
                    '32A-DD',
                    '34A-DD',
                    '36B-DD',
                    '38C-D',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '36A',
                    '38B',
                    '38DD'
                ]
            ],
        }
    },
    // {
    //     name: 'Push-up Bra Top',
    //     slug: 'vsxy_push_up_bra_top',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'very_sexy',
    //             label: 'VERY SEXY',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'bra_top',
    //             label: 'BRA TOP',
    //             order: 1,
    //         },
    //         {
    //             slug: 'push_up',
    //             label: 'PUSH-UP',
    //             order: 2,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/push_up_bra_top/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/push_up_bra_top/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/push_up_bra_top/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/push_up_bra_top/poster.jpg',
    //     },
    //     spec: [
    //         'Push-up padding',
    //         'Adds 1 cup size (medium lift)',
    //         'Underwire',
    //         'Adjustable straps',
    //         'Hook-and-eye closures at back',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 '32B',
    //                 '32C',
    //                 '34B',
    //                 '34C',
    //                 '34D',
    //                 '34DD (E)',
    //                 '36C',
    //                 '36D',
    //                 '36DD (E)',
    //                 '38C',
    //                 '38D',
    //                 '38DD (E)',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 '32B-C',
    //                 '34B-DD',
    //                 '36C-DD',
    //                 '38C-DD',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: "32B-C, 34B-DD, 36C-DD, 38C-DD",
    //         moreOnline: []
    //     }
    // },
    // {
    //     name: 'Bombshell Bra Top',
    //     alternativeName: [
    //         'BOMBSHELL BRA TOP'
    //     ],
    //     slug: 'vsxy_bombshell_bra_top',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'very_sexy',
    //             label: 'VERY SEXY',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'bra_top',
    //             label: 'BRA TOP',
    //             order: 2,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell_bra_top/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell_bra_top/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell_bra_top/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell_bra_top/poster.jpg',
    //     },
    //     spec: [
    //         'Push-up padding',
    //         'Adds 2 cups size (maximum lift)',
    //         'Underwire',
    //         'Adjustable straps',
    //         'Hook-and-eye closures at back',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 '32A',
    //                 '32B',
    //                 '32C',
    //                 '32D',
    //                 '34A',
    //                 '34B',
    //                 '34C',
    //                 '34D',
    //                 '36B',
    //                 '36C',
    //                 '36D',
    //                 '38C',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 '32A-D',
    //                 '34A-D',
    //                 '36B-D',
    //                 '38C',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [
    //             [
    //                 '32A-D',
    //                 '34A-D',
    //                 '36B-D',
    //                 '38C',
    //             ]
    //         ],
    //         moreOnline: [],
    //     }
    // },
    // {
    //     name: 'Unlined Demi',
    //     slug: 'vsxy_unlined_demi',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'very_sexy',
    //             label: 'VERY SEXY',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'unlined',
    //             label: 'UNLINED',
    //             order: 1,
    //         },
    //         {
    //             slug: 'demi',
    //             label: 'DEMI',
    //             order: 1,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/unlined_demi/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/unlined_demi/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/unlined_demi/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/unlined_demi/poster.jpg',
    //     },
    //     spec: [
    //         'Plunge neckline',
    //         'Unlined',
    //         'Adjustable straps',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 '32B',
    //                 '32C',
    //                 '32D',
    //                 '32DD (E)',
    //                 '34B',
    //                 '34C',
    //                 '34D',
    //                 '34DD (E)',
    //                 '36B',
    //                 '36C',
    //                 '36D',
    //                 '36DD (E)',
    //                 '38C',
    //                 '38D',
    //                 '38DD (E)',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 '32B-DD',
    //                 '34B-DD',
    //                 '36B-DD',
    //                 '38C-DD',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: "32B-DDD, 34B-DDD, \n36B-DDD, 38C-DD",
    //         moreOnline: []
    //     }
    // },
    {
        name: 'Bombshell Strapless',
        slug: 'vsxy_bombshell_strapless',
        selectStores: false,
        collections: [
            {
                slug: 'very_sexy',
                label: 'VERY SEXY',
                isPrimary: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell_strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell_strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell_strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/very_sexy_bras/bombshell_strapless/poster.jpg',
        },
        spec: [
            'Provides maximum lift & shape',
            'U-shaped ballet back for a comfortable fit',
            'Fully adjustable and removable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',

                    '34A',
                    '34B',
                    '34C',
                    '34D',

                    '36B',
                    '36C',
                    '36D',

                    '38C',
                ]
            ],
            abbreviated: [
                [
                    '32A-D',
                    '34A-D',
                    '36B-D',
                    '38C',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32AA',
                    '32DD',
                    '34AA',
                    '34DD',
                    '36DD',
                    '38D-DD'
                ]
            ],
        }
    },

    // dream angels
    {
        name: 'Demi',
        slug: 'da_demi',
        selectStores: false,
        collections: [
            {
                slug: 'dream_angels',
                label: 'DREAM ANGELS',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 1,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 1,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 3,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/demi/poster.jpg',
        },
        spec: [
            'Lightly lined pad',
            'Memory fit lining',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32-36A',
                    '32-36G,'
                ],
                [
                    '38B',
                    '38G',
                    '40C-DDD',
                    '42D-DD',
                    '44C-D'
                ]
            ],
        }
    },
    {
        name: 'Push-Up',
        slug: 'da_push_up',
        selectStores: false,
        collections: [
            {
                slug: 'dream_angels',
                label: 'DREAM ANGELS',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 5,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 4,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/push_up/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/push_up/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Memory fit push-up pad',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',

                    '38C',
                    '38D',
                ]
            ],

            abbreviated: [
                [
                    '32A-DD',
                    '34A-DD',
                    '36B-D',
                    '38C-D'
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32-34DDD',
                    '36A',
                    '36DD-DDD,'
                ],
                [
                    '38B',
                    '38DD-DDD'
                ]
            ],
        }
    },
    {
        name: 'Wicked Unlined Balconette',
        sizedProductName: [
            'Wicked Unlined', 'Balconette',
        ],
        alternativeName: [
            'Wicked Unlined',
            'Balconette',
        ],
        slug: 'da_wicked',
        selectStores: false,
        collections: [
            {
                slug: 'dream_angels',
                label: 'DREAM ANGELS',
                isPrimary: true,
            },
            {
                slug: 'unlined',
                label: 'UNLINED',
                order: 1,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 5,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wicked/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wicked/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wicked/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wicked/poster.jpg',
        },
        spec: [
            'Unlined',
            'Push-up without padding',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32-34A',
                    '38B,'
                ],
                [
                    '32-38G',
                    '40DD-DDD',
                    '42C-DD',
                    '44C-D'
                ]
            ],
        }
    },
    // {
    //     name: 'Wireless',
    //     slug: 'da_wireless',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'dream_angels',
    //             label: 'DREAM ANGELS',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'wireless',
    //             label: 'WIRELESS',
    //             order: 1,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wireless/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wireless/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wireless/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/wireless/poster.jpg',
    //     },
    //     spec: [
    //         'Soft lace cups and wings',
    //         'No wires (really!)',
    //         'Supportive, plunging silhouette',
    //     ],
    //     sizes: {
    //         primary: [
    //         [
    //             'XS',
    //             'S',
    //             'SDD',
    //             'M',
    //             'MDD',
    //             'L',
    //             'LDD',
    //             'XL',
    //             '32A',
    //             '32B',
    //             '32C',
    //             '32D',
    //             '32DD (E)',
    //             '32DDD (F)',
    //             '34A',
    //             '34B',
    //             '34C',
    //             '34D',
    //             '34DD (E)',
    //             '34DDD (F)',
    //             '36B',
    //             '36C',
    //             '36D',
    //             '36DD (E)',
    //             '36DDD (F)',
    //             '38B',
    //             '38C',
    //             '38D',
    //             '38DD (E)',
    //             '38DDD (F)',
    //         ]
    //     ],
    //         abbreviated: [
    //             [
    //                 '32C-DD',
    //                 '34B-DD',
    //                 '36B-DD',
    //                 '38B-DD',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [],
    //         moreOnline: [
    //             [
    //                 '32A',
    //                 '32B',
    //                 '34A',
    //                 '40C-D',
    //             ]
    //         ]
    //     }
    // },
    // {
    //     name: 'Strapless',
    //     slug: 'da_strapless',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'dream_angels',
    //             label: 'DREAM ANGELS',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'strapless',
    //             label: 'STRAPLESS',
    //             order: 2,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/strapless/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/strapless/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/strapless/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/strapless/poster.jpg',
    //     },
    //     spec: [
    //         'Lightly lined pad',
    //         'Memory fit lining',
    //         'Soft, elastic lace band',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 '32B',
    //                 '32C',
    //                 '32D',
    //                 '32DD (E)',
    //                 '32DDD (F)',
    //                 '34B',
    //                 '34C',
    //                 '34D',
    //                 '34DD (E)',
    //                 '34DDD (F)',
    //                 '36B',
    //                 '36C',
    //                 '36D',
    //                 '36DD (E)',
    //                 '36DDD (F)',
    //                 '38C',
    //                 '38D',
    //                 '38DD (E)',
    //                 '38DDD (F)',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 '32-36B-DDD',
    //                 '38C-DDD',
    //             ]
    //         ],
    //         selectStores: [
    //             [
    //                 '32-34A',
    //                 '38B',
    //             ]
    //         ],
    //         online: [],
    //         moreOnline: [],
    //         strapless: '32-34B-DDD, 36C-DDD',
    //     }
    // },
    {
        name: 'Unlined Lace-up Corset Top',
        alternativeName: [
            'Unlined Lace-up',
            'Corset Top',
        ],
        slug: 'da_unlined_lace_up_corset_top',
        selectStores: false,
        collections: [
            {
                slug: 'dream_angels',
                label: 'DREAM ANGELS',
                isPrimary: true,
            },
            {
                slug: 'unlined',
                label: 'UNLINED',
                order: 2,
            },
            {
                slug: 'bra_top',
                label: 'BRA TOP',
                order: 1,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/unlined_lace_up_corset_top/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/unlined_lace_up_corset_top/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/unlined_lace_up_corset_top/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/dream_angels_bras/unlined_lace_up_corset_top/poster.jpg',
        },
        spec: [
            'Unlined',
            'Wireless',
            'Adjustable straps',
            'Hook-and-eye closures at back',
        ],
        sizes: {
            primary: [
                [
                    'XS',
                    'S',
                    'SDD',
                    'M',
                    'MDD',
                    'L',
                    'XL',
                ]
            ],
            abbreviated: [
                [
                    'XS',
                    'S',
                    'SDD',
                    'M',
                    'MDD',
                    'L',
                    'XL',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    'XXL'
                ]
            ]
        }
    },

    // body by victoria
    {
        name: 'Demi',
        slug: 'bbv_demi',
        selectStores: false,
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 2,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 2,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 11,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi/poster.jpg',
        },
        spec: [
            'Moderate-coverage cup',
            'Lightly lined',
            'Memory fit for extra support',
            'Padded straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-34A-DDD',
                    '36-38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32G',
                    '34G',
                    '36A,',
                ],
                [
                    '36G',
                    '38G',
                    '40C-DDD',
                    '42C-DD',
                    '44C-D',
                ]
            ]
        }
    },
    {
        name: 'Demi Racerback',
        slug: 'bbv_demi-racerback',
        sizedProductName: ['Demi', 'Racerback'],
        selectStores: false,
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 3,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi_racerback/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi_racerback/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi_racerback/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/demi_racerback/poster.jpg',
        },
        spec: [
            'Lightly lined',
            'Memory Fit lining conforms to curves',
            'Smooth silhouette prevents bulging',
            'Front-close for easy on & off',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-34A-DDD',
                    '36-38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32G',
                    '34G',
                    '36A,',
                ],
                [
                    '36G',
                    '38G',
                    '40C-DDD',
                    '42C-DD',
                    '44C-D',
                ]
            ]
        }
    },
    {
        name: 'Full-Coverage',
        slug: 'bbv_full_coverage',
        selectStores: false,
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'full_coverage',
                label: 'Full-Coverage',
                order: 1
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 3,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 12,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/full_coverage/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/full_coverage/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/full_coverage/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/full_coverage/poster.jpg',
        },
        spec: [
            'Full-Coverage Cup',
            'Lightly Lined',
            'Memory Fit for Extra Support',
            'Padded Straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32C',
                    '32G',
                    '34G',
                    '36G',
                    '38B,'
                ],
                [
                    '38G',
                    '40C-DDD',
                    '42C-DD',
                    '44C-D',
                ]
            ],
        }
    },
    {
        name: 'Full-Coverage Racerback',
        sizedProductName: ['Full-Coverage', 'Racerback'],
        slug: 'bbv_full-coverage-racerback',
        selectStores: false,
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'full_coverage',
                label: 'FULL COVERAGE',
                order: 2,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/full_coverage_racerback/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/full_coverage_racerback/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/full_coverage_racerback/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/full_coverage_racerback/poster.jpg',
        },
        spec: [
            'Lightly lined',
            'Memory Fit lining conforms to curves',
            'Smooth silhouette prevents bulging',
            'Front-close for easy on & off',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32C',
                    '32G',
                    '34G',
                    '36G',
                    '38B,'
                ],
                [
                    '38G',
                    '40C-DDD',
                    '42C-DD',
                    '44C-D',
                ]
            ],
        }
    },
    {
        name: 'Perfect Shape',
        slug: 'bbv_perfect_shape',
        selectStores: false,
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'perfect_shape',
                label: 'Perfect Shape',
                order: 1
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 13,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_shape/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_shape/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_shape/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/perfect_shape/poster.jpg',
        },
        spec: [
            'Full-coverage up',
            'Light push-up padding',
            'Memory fit for extra support',
            'Padded straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32-38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32A',
                    '32G',
                    '34A,',
                ],
                [
                    '34G',
                    '36A',
                    '36G',
                    '40C-DDD',
                    '42C-DD',
                ]
            ]
        }
    },
    {
        name: 'Push-Up',
        slug: 'bbv_push_up',
        selectStores: false,
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 6,
            },
        ],
        images: {
            front:'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/push_up/front.jpg',
            back:'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/push_up/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Push-up padding',
            'Memory fit for extra support',
            'Padded straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',

                    '34B',
                    '34C',
                    '34D',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32B-C',
                    '34B-D',
                    '36B-DD',
                    '38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32A',
                    '32D-DDD',
                    '34A,',

                ],
                [
                    '34DD-DDD',
                    '36A',
                    '36DDD',
                    '40C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Strapless',
        slug: 'bbv_strapless',
        selectStores: false,
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 4,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 2,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/strapless/poster.jpg',
        },
        spec: [
            'Full-coverage cup',
            'Lightly lined',
            'Memory fit for extra support',
            'Removable straps',
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32A-B',
                    '32G',
                    '34A,',
                ],
                [
                    '34G',
                    '36G',
                    '38B',
                    '40C-DD',
                ]
            ]
        }
    },
    {
        name: 'Fabulous \nFull Cup',
        slug: 'bbv_fabulous-full-cup',

        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/fabulous_full_cup/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/fabulous_full_cup/back.png',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/fabulous_full_cup/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/fabulous_full_cup/poster.jpg',
        },
        spec: [
            'Unlined',
            '3-pieces construction shapes & lifts,',
            'providing a natural look',
            'Higher center gore contains & separates',
            'Lace made with partially recycled materials',
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-DDD',
                    '34B-DDD',
                    '36C-DDD',
                    '38D-DDD',
                ],
            ],
            selectStores:[],
            online: [],
            moreOnline: [
                [

                    '32B',
                    '32G',
                    '34G',
                    '36G',
                    '38C,',
                ],
                [
                    '38G',
                    '40C-DDD',
                    '40G',
                    '42C-DDD',
                    '42G',
                    '44C-D',
                ]
            ]
        }
    },
    // {
    //     name: 'Unlined Demi',
    //     slug: 'bbv_unlined_demi',
    //     selectStores: true,
    //     collections: [
    //         {
    //             slug: 'body_by_victoria',
    //             label: 'BODY BY VICTORIA',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'unlined',
    //             label: 'UNLINED',
    //             order: 3,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/unlined_demi/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/unlined_demi/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/unlined_demi/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/unlined_demi/poster.jpg',
    //     },
    //     spec: [
    //         'Moderate-coverage cup',
    //         'Unlined',
    //         'Fully adjustable straps',
    //         'U-shaped back for smoothing',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 '32B',
    //                 '32C',
    //                 '32D',
    //                 '32DD (E)',
    //                 '32DDD (F)',
    //                 '34B',
    //                 '34C',
    //                 '34D',
    //                 '34DD (E)',
    //                 '36C',
    //                 '36D',
    //                 '36DD (E)',
    //                 '38D',
    //                 '38DD (E)',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 '32-34B-DDD',
    //                 '36C-DDD',
    //                 '38D-DDD',
    //             ],
    //         ],
    //         selectStores:[],
    //         online: [],
    //         moreOnline: "30C-D, 32-34B-DDD, \n36C-DDD, 38D-DDD, 38G, 40DD-DDD, \n42D-DD, 44C-D"
    //     }
    // },
    // {
    //     name: 'Wireless',
    //     slug: 'bbv_wireless',
    //     selectStores: true,
    //     collections: [
    //         {
    //             slug: 'body_by_victoria',
    //             label: 'BODY BY VICTORIA',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'unlined',
    //             label: 'UNLINED',
    //             order: 4,
    //         },
    //         {
    //             slug: 'wireless',
    //             label: 'WIRELESS',
    //             order: 1,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/wireless/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/wireless/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/wireless/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/wireless/poster.jpg',
    //     },
    //     spec: [
    //         'Lightly lined',
    //         'Memory fit lining for extra support',
    //         'Wireless cups',
    //         'Fully adjustable straps',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 '32A',
    //                 '32B',
    //                 '32C',
    //                 '32D',
    //                 '32DD (E)',
    //                 '34A',
    //                 '34B',
    //                 '34C',
    //                 '34D',
    //                 '34DD (E)',
    //                 '36B',
    //                 '36C',
    //                 '36D',
    //                 '36DD (E)',
    //                 '38C',
    //                 '38D',
    //                 '38DD (E)',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 '32A-DD',
    //                 '34A-DD',
    //                 '36B-DD',
    //                 '38C-DD',
    //             ],
    //         ],
    //         selectStores: [],
    //         online: [],
    //         moreOnline: [
    //             [
    //                 '32-36A-DD',
    //                 '38B-DD',
    //                 '40C-DD'
    //             ],
    //         ]
    //     }
    // },
    {
        name: 'Nursing Bra',
        slug: 'bbv_maternity',
        selectStores: false,
        collections: [
            {
                slug: 'body_by_victoria',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
            },
            {
                slug: 'wireless',
                label: 'WIRELESS',
                order: 2,
            },
            {
                slug: 'nursing_bra',
                label: 'NURSING BRA',
                order: 1,
            },
            {
                slug: 'solutions',
                label: 'BODY BY VICTORIA',
                isPrimary: true,
                order: 5,
            }
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/nursing_bra/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/nursing_bra/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/nursing_bra/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/body_by_victoria/nursing_bra/poster.jpg',
        },
        spec: [
            'Wireless frame',
            'Clip function at the cup',
            'Leak resistant and absorbent pad liner',
            'Fully adjustable padded straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [
                [
                    '38B',
                    '40C-DD',
                ]
            ],
            moreOnline: []
        }
    },

    //love cloud
    {
        name: 'Wireless Push-Up',
        slug: 'lc_wireless-push-up',
        selectStores: false,
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'INCREDIBLE BY \nVICTORIA\'S SECRET',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 7,
            },
            {
                slug: 'wireless',
                label: 'WIRELESS',
                order: 3,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/wireless_push_up/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/wireless_push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/wireless_push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/wireless_push_up/poster.jpg',

        },
        spec: [
            'Push-up padding',
            'Wireless silhouette',
            'Smoothing back and band',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',

                ]
            ],
            abbreviated: [
                [
                    '32B-DD',
                    '34B-DD',
                    '36B-DDD',
                ],
                [
                    '38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32A',
                    '32DDD',
                    '34DDD,',
                ],
                [
                    '40-44C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Lightly Lined Demi', // renamed from 'Smooth Demi'
        slug: 'lc_lightly-lined-demi',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 3,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 5,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_demi/poster.jpg',

        },
        spec: [
            'Cloud-like padding',
            'Lightly lined',
            'Adjustable straps',
            'Side and back-smoothing Incredible technology',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32A',
                    '32B',
                    '32G,',
                ],
                [
                    '34A',
                    '34G',
                    '36A',
                    '36G',
                    '38B',
                    '38G',
                    '40-44C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Push-up \nplunge bra',
        sizedProductName: [
            'Push-up plunge bra',
        ],
        alternativeName: [
            'Push-up plunge'
        ],
        slug: 'lc_push_up_plunge',
        selectStores: false,
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 8,
            },
            {
                slug: 'bra_top',
                label: 'BRA TOP',
                order: 2,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/push_up_plunge/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/push_up_plunge/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/push_up_plunge/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/push_up_plunge/poster.jpg',

        },
        spec: [
            'Cloud-like padding',
            'Adjustable straps',
            'Side and back-smoothing Incredible technology',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32B-D',
                    '34B-DD',
                    '36B-DDD,',
                ],
                [
                    '38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32A',
                    '32B',
                    '32DD',
                    '32DDD,',
                ],
                [
                    '34A',
                    '34DDD',
                    '36A',
                    '40-44 C-DDD',
                ]
            ]
        }
    },
    {
        name: 'Lightly Lined Plunge',
        sizedProductName: [
            'Lightly Lined Plunge',
        ],
        alternativeName: [
            'Lightly Lined',
            'Plunge'
        ],
        slug: 'lc_lightly-lined-plunge',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 6,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lightly_lined_plunge/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lightly_lined_plunge/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lightly_lined_plunge/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lightly_lined_plunge/poster.jpg',

        },
        spec: [
            '1/2 cup coverage with light lining',
            'Cloud-like half lining for a comfortable,',
            'natural shape',
            'Elongated cup with deep plunge for open necklines',
            'Made with partially recycled lace',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-DDD',
                    '34B-DDD',
                    '36-38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '40-44C-DDD'
                ]
            ]
        }
    },
    {
        name: 'Smooth Strapless \nBra',
        sizedProductName: [
            'Smooth Strapless Bra',
        ],
        alternativeName: [
            'Smooth',
            'Strapless Bra',
        ],
        slug: 'lc_smooth-strapless-bra',
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 3,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_strapless/poster.jpg',

        },
        spec: [
            'Lightly Lined',
            'Cloud-like lining for a comfortable, natural shape',
            'Smoothing back and sides around the band',
            'Wireless bandeau pull-over provides an easy fit',
        ],
        sizes: {
            primary: [
                [
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                ]
            ],
            abbreviated: [
                [
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    'SDD',
                    'MDD',
                    'LDD',
                ]
            ]
        }
    },
    {
        name: 'Front-close Full Coverage',
        sizedProductName: [
            'Front-close full coverage',
        ],
        alternativeName: [
            'Front-close',
            'Full Coverage',
        ],
        slug: 'lc_front_close_demi',
        selectStores: false,
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
            {
                slug: 'full_coverage',
                label: 'Full-Coverage',
                order: 3
            }
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/front_close_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/front_close_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/front_close_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/front_close_demi/poster.jpg',

        },
        spec: [
            'Cloud-like uplift push-up',
            'Adjustable straps',
            'Lace side and back panels',
            'Front hook closure',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                ['40-44C-DDD']
            ]
        }
    },
    {
        name: 'Lace half-pad plunge bra',
        sizedProductName: [
            'Lace half-pad plunge bra',
        ],
        alternativeName: [
            'Lace half-pad',
            'plunge bra'
        ],
        slug: 'lc_lace_half_pad_plunge',
        selectStores: false,
        collections: [
            {
                slug: 'love_cloud_bras',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 7,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lace_half_pad_plunge/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lace_half_pad_plunge/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lace_half_pad_plunge/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/lace_half_pad_plunge/poster.jpg',

        },
        spec: [
            'Cloud-like padding',
            '\u00BD cup coverage with light lining',
            'Adjustable straps',
            'Side and back-smoothing Incredible technology',
            'Gold V-hardware'
        ],
        sizes: {
            primary: [
                [
                    '32C',
                    '32D',

                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32C-D',
                    '34C-DDD',
                    '36C-DDD,',
                ],[
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32C-DDD',
                    '34B-DDD,',
                ],
                [
                    '36C-DD',
                    '38-40C-DDD',
                ]
            ]
        }
    },
    // {
    //     name: 'Smooth straight-neck lounge bra',
    //     sizedProductName: [
    //         'Smooth straight-neck',
    //         'lounge bra',
    //     ],
    //     alternativeName: [
    //         'Smooth',
    //         'straight-neck',
    //         'lounge bra'
    //     ],
    //     slug: 'lc_smooth_straight_neck_lounge',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'love_cloud_bras',
    //             label: 'LOVE CLOUD',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'wireless',
    //             label: 'WIRELESS',
    //             order: 6,
    //         },
    //         {
    //             slug: 'lightly_lined',
    //             label: 'LIGHTLY LINED',
    //             order: 11,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_straight_neck_lounge/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_straight_neck_lounge/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_straight_neck_lounge/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/smooth_straight_neck_lounge/poster.jpg',
    //
    //     },
    //     spec: [
    //         'Cloud-like padding',
    //         'Adjustable straps',
    //         'Side and back-smoothing Incredible technology',
    //         'Wireless silhouette',
    //         'Gold V-hardware'
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 'XS',
    //                 'S',
    //                 'SDD',
    //                 'M',
    //                 'MDD',
    //                 'L',
    //                 'LDD',
    //                 'XL',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 'XS',
    //                 'S',
    //                 'SDD',
    //                 'M',
    //                 'MDD',
    //                 'L',
    //                 'LDD',
    //                 'XL'
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [],
    //         moreOnline: []
    //     }
    // },
    // {
    //     name: 'Sport bra',
    //     slug: 'lc_sport',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'love_cloud_bras',
    //             label: 'LOVE CLOUD',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'sport',
    //             label: 'SPORT',
    //             order: 5,
    //
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/sport/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/sport/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/sport/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/love_cloud_bras/sport/poster.jpg',
    //
    //     },
    //     spec: [
    //         'Minimum support',
    //         'Ultra-stretchy, soft foam pad',
    //         'Wireless for 24-hour comfort',
    //         'Moisture-wicking fabric',
    //         'Fully adjustable straps'
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 'XS',
    //                 'S',
    //                 'M',
    //                 'L',
    //                 'XL',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 'XS-XL'
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [],
    //         moreOnline: [
    //             [
    //                 'XS',
    //                 'S',
    //                 'SDD',
    //                 'M',
    //                 'MDD',
    //                 'L',
    //                 'XL',
    //                 'XXL'
    //             ]
    //         ]
    //     }
    // },

    // incredible
    // {
    //     name: 'Demi',
    //     slug: 'inc_demi',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'incredible',
    //             label: 'INCREDIBLE',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'demi',
    //             label: 'DEMI',
    //             order: 5,
    //         },
    //         {
    //             slug: 'lightly_lined',
    //             label: 'LIGHTLY LINED',
    //             order: 8,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_demi/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_demi/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_demi/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_demi/poster.jpg',
    //     },
    //     spec: [
    //         'Moderate-coverage cup',
    //         'Lightly lined',
    //         'Cushioned underwire',
    //         'Smoothing back and band',
    //     ],
    //     sizes: {
    //     primary: [
    //     [
    //         'XS',
    //         'S',
    //         'SDD',
    //         'M',
    //         'MDD',
    //         'L',
    //         'LDD',
    //         'XL',
    //         '32A',
    //         '32B',
    //         '32C',
    //         '32D',
    //         '32DD (E)',
    //         '32DDD (F)',
    //         '34A',
    //         '34B',
    //         '34C',
    //         '34D',
    //         '34DD (E)',
    //         '34DDD (F)',
    //         '36B',
    //         '36C',
    //         '36D',
    //         '36DD (E)',
    //         '36DDD (F)',
    //         '38B',
    //         '38C',
    //         '38D',
    //         '38DD (E)',
    //         '38DDD (F)',
    //     ]
    // ],
    //         abbreviated: [
    //             [
    //             '32B-DD',
    //             '34B-DDD',
    //             '36B-DDD',
    //             '38B-DDD',
    //             ]
    //         ],
    //         selectStores: [
    //             [
    //             '32A',
    //             '34A',
    //             '32DDD',
    //             ]
    //         ],
    //         online: [],
    //         moreOnline: [
    //             [
    //                 '40C-DDD',
    //             ]
    //         ]
    //     }
    // },
    // {
    //     name: 'Perfect Shape',
    //     slug: 'inc_perfect_shape',
    //     collections: [
    //         {
    //             slug: 'incredible',
    //             label: 'INCREDIBLE',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'perfect_shape',
    //             label: 'Perfect Shape',
    //             order: 3
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/perfect_shape/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/perfect_shape/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/perfect_shape/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/perfect_shape/poster.jpg',
    //     },
    //     spec: [
    //         'Full-coverage cup',
    //         'Natural lift push-up',
    //         'Cushioned underwire',
    //         'Smoothing back and band',
    //     ],
    //     sizes: {
    //         abbreviated: [
    //             [
    //                 '32-36B-DDD',
    //                 '38C-DDD',
    //             ]
    //         ],
    //         selectStores: [
    //             [
    //                 '32A',
    //                 '34A',
    //                 '38B',
    //             ]
    //         ],
    //         online: [],
    //         moreOnline: [
    //             [
    //                 '40C-DDD',
    //             ]
    //         ]
    //     }
    // },

    // vs bare
    {
        name: 'Infinity Flex',
        slug: 'vsb_infinity_flex',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 14,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex/poster.jpg',
        },
        spec: [
            'Flexible adaptive fit technology',
            '4-way stretch adapts to your shape',
            'Gel wire for a flexible & comfortable lift',
            'Floating hideaway pad',
            'Smoothing wing & sleek back prevent bulging',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DDD',
                    '34B-DD',
                    '36B-DDD,',
                ],[
                    '38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '34DDD',
                    '38G,',
                ],[
                    '40DD-DDD',
                    '42D-DD',
                    '44C-D',
                ]
            ]
        }
    },
    {
        name: 'Infinity Flex Perfect Shape',
        sizedProductName: [
            'Infinity Flex Perfect Shape',
        ],
        alternativeName: [
            'INFINITY FLEX',
            'PERFECT SHAPE'
        ],
        slug: 'vsb_infinity_flex_perfect_shape',
        selectStores: false ,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'perfect_shape',
                label: 'PERFECT SHAPE',
                order: 2,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex_perfect_shape/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex_perfect_shape/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex_perfect_shape/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/infinity_flex_perfect_shape/poster.jpg',
        },
        spec: [
            'Flexible gel wire that feels wireless',
            'Smoothing wing & sleek back to prevent bulging',
            'Light push-up padding',
            'Full coverage cups',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DDD',
                    '34B-DD',
                    '36B-DDD,',
                ],[
                    '38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '34DDD',
                    '38G,',
                ],[
                    '40DD-DDD',
                    '42D-DD',
                    '44C-D',
                ]
            ]
        }
    },
    {
        name: 'Sexy Illusions Lightly Lined Strapless',
        sizedProductName: [
            'Illusions Lightly Lined', 'Strapless',
        ],
        alternativeName: [
            'Illusion Lightly',
            'Lined Strapless',
        ],
        slug: 'vsb_strapless',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 7,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 4,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless/poster.jpg',
        },
        spec: [
            'Lightly lined',
            'VS side smoothing solutions',
            'Exclusive non-slip cup and band technology',
            'Removable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',

                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DDD',
                    '34B-DDD,',
                ],[
                    '36B-DD',
                    '38C-DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32A',
                    '34A',
                    '36DD',
                    '38B,',
                ],[
                    '38DDD',
                    '38G',
                    '40-42C-DDD',
                    '44C-DD',
                ]
            ]
        }
    },
    {
        name: 'Sexy Illusions Uplift Strapless',
        alternativeName: [
            'Sexy Illusions',
            'Uplift Strapless',
        ],
        slug: 'vsb_strapless_uplift',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 5,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless_uplift/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless_uplift/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless_uplift/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/strapless_uplift/poster.jpg',
        },
        spec: [
            'Push-up',
            'VS side smoothing solutions',
            'Exclusive non-slip cup and brand technology',
            'Removable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',

                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32A-DD',
                    '34A-DD',
                    '36B-DD',
                    '38C-DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32DDD',
                    '34DDD',
                    '36DDD,',
                ],[
                    '38B',
                    '40-42C-DDD',
                    '44C-DD',
                ]
            ]
        }
    },
    // {
    //     name: 'Wireless Push-Up',
    //     slug: 'inc_wireless_push_up',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'vs_bare',
    //             label: 'INCREDIBLE',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'push_up',
    //             label: 'PUSH-UP',
    //             order: 7,
    //         },
    //         {
    //             slug: 'wireless',
    //             label: 'WIRELESS',
    //             order: 4,
    //         },
    //         // {
    //         //     slug: 'most_wanted',
    //         //     label: 'MOST WANTED',
    //         //     order: 15,
    //         // },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_wireless/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_wireless/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_wireless/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/incredible_wireless/poster.jpg',
    //     },
    //     spec: [
    //         'Push-up padding',
    //         'Wireless silhouette',
    //         'Smoothing back and band',
    //         'Fully adjustable straps',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 '32B',
    //                 '32C',
    //                 '32D',
    //                 '32DD (E)',
    //                 '32DDD (F)',
    //                 '34B',
    //                 '34C',
    //                 '34D',
    //                 '34DD (E)',
    //                 '34DDD (F)',
    //                 '36B',
    //                 '36C',
    //                 '36D',
    //                 '36DD (E)',
    //                 '36DDD (F)',
    //                 '38B',
    //                 '38C',
    //                 '38D',
    //                 '38DD (E)',
    //                 '38DDD (F)',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 '32-38B-DDD'
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [],
    //         moreOnline: [
    //             [
    //                 '32A',
    //                 '34A',
    //                 '40C-DDD',
    //             ]
    //         ]
    //     }
    // },
    {
        name: 'Angelight Full Coverage',
        alternativeName: [
            'ANGELIGHT',
            'FULL COVERAGE'
        ],
        slug: 'vsb_angel_light_full_coverage',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'full_coverage',
                label: 'FULL COVERAGE',
                order: 4,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/angel_light_full_coverage/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/angel_light_full_coverage/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/angel_light_full_coverage/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/angel_light_full_coverage/poster.jpg',
        },
        spec: [
            'Lightly Lined',
            'Breathable, light Spacer Pad Technology',
            'keeps you cool',
            'Soft fabric shapes and defines',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '38B',
                    '40C-DD',
                    '42C-D',
                    '44C',
                ]
            ]
        }
    },
    {
        name: 'Angelight \nPush-Up',
        slug: 'vsb_angelight-push-up',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 9,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/angelight_push_up/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/angelight_push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/angelight_push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/vs_bare_bras/angelight_push_up/poster.jpg',
        },
        spec: [
            'Push-up Padding',
            'Breathable, light Spacer Pad Technology',
            'keeps you cool',
            'Soft fabric shapes and defines',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '38B',
                    '40C-DD',
                    '42C-D',
                    '44C',
                ]
            ]
        }
    },
    {
        name: 'Every-Way Strapless Bra',
        alternativeName: [
            'Every-Way',
            'Strapless Bra',
        ],
        slug: 'vsb_every-way-strapless',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'solutions',
                label: 'VICTORIA\'S SECRET \nBARE',
                order: 1
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 6,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/every_way_strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/every_way_strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/every_way_strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/every_way_strapless/poster.jpg',
        },
        spec: [
            'Uplift light push-up padding',
            '12+ ways to wear',
            'Non-slip cup & band technology keeps everything in place',
            'Fully adjustable straps (set of regular & clear)',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DD',
                    '34B-DD',
                    '36B-DD',
                    '38B-DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32DDDD', '34DDD', '36DDD',
                ]
            ],
        }
    },
    {
        name: 'Plunge Low-Back Bra',
        alternativeName: [
            'Plunge',
            'Low-Back Bra',
        ],
        slug: 'vsb_plunge-low-back',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'solutions',
                label: 'VICTORIA\'S SECRET \nBARE',
                order: 2
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/plunge_low_back/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/plunge_low_back/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/plunge_low_back/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/plunge_low_back/poster.jpg',
        },
        spec: [
            'Uplift light push-up padding',
            'Low neckline with deep scoop back',
            'Exclusive non-slip cup-and-back technology',
            'Nearly invisible under clothes',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)'
                ]
            ],
            abbreviated: [
                [
                    '32B-DDD',
                    '34B-DD',
                    '36B-DDD,',
                ],[
                    '38B-DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '34DDD', '38DDD,',
                ],
                [
                    '40C-DDD', '42C-DD', '44C-D'
                ]
            ],
        }
    },
    {
        name: 'Convertible \nShine Bra',
        alternativeName: [
            'Convertible',
            'Shine Bra',
        ],
        slug: 'vsb_convertible-shine',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'solutions',
                label: 'VICTORIA\'S SECRET \nBARE',
                order: 3
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/convertible_shine/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/convertible_shine/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/convertible_shine/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/convertible_shine/poster.jpg',
        },
        spec: [
            'Uplift light push-up padding',
            'Bling strap meant to be shown off',
            'Nearly invisible under clothes',
            'Adjustable straps and band',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',
                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)'
                ]
            ],
            abbreviated: [
                [
                    '32B-DDD',
                    '34B-DD',
                    '36B-DDD,',
                ],[
                    '38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '34DDD', '40C-DDD,'
                ],
                [
                    '42C-DD', '44C-D'
                ]
            ],
        }
    },
    {
        name: 'Backless \nStrapless Bra',
        sizedProductName: [
            'Backless Strapless',
        ],
        alternativeName: [
            'Backless',
            'Strapless Bra',
        ],
        slug: 'vsb_backless-strapless',
        selectStores: false,
        collections: [
            {
                slug: 'vs_bare',
                label: 'VICTORIA\'S SECRET \nBARE',
                isPrimary: true,
            },
            {
                slug: 'solutions',
                label: 'VICTORIA\'S SECRET \nBARE',
                order: 4
            },
            {
                slug: 'strapless',
                label: 'STRAPLESS',
                order: 7
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/backless_strapless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/backless_strapless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/backless_strapless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/solutions/backless_strapless/poster.jpg',
        },
        spec: [
            'Lightly lined padding',
            'Reusable, body-safe adhesive',
            'Nearly invisible under clothes',
        ],
        sizes: {
            primary: [
                [
                    'A',
                    'AA',
                    'B',
                    'C',
                    'D',
                    'DD',
                ]
            ],
            abbreviated: [
                [
                    'AA/A',
                    'B/C',
                    'D/DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
            ],
        }
    },

    // t-shirt
    {
        name: 'Demi',
        slug: 'ts_demi',
        selectStores: false,
        collections: [
            {
                slug: 't_shirt',
                label: 'T-SHIRT BRA',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 4,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 9,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 6,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_demi/poster.jpg',
        },
        spec: [
            'Moderate-coverage cup',
            'Lightly lined',
            'Fully adjustable straps',
            'Can be worn classic or crossback',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '40C-DD',
                ]
            ]
        }
    },
    {
        name: 'Perfect Shape',
        slug: 'ts_perfect_shape',
        selectStores: false,
        collections: [
            {
                slug: 't_shirt',
                label: 'T-SHIRT BRA',
                isPrimary: true,
            },
            {
                slug: 'perfect_shape',
                label: 'Perfect Shape',
                order: 3
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 8,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_perfect_shape/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_perfect_shape/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_perfect_shape/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_perfect_shape/poster.jpg',
        },
        spec: [
            'Full-coverage cup',
            'Natural lift push-up',
            'Fully adjustable straps',
            'Can be worn classic or crossback',
        ],
        sizes: {
            primary: [
                [
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32B-DD, 34B-DD, 36B-DDD,'
                ],[
                    '38B-DDD'
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32A',
                    '32G',
                    '34A,',
                ],[
                    '34DDD-G',
                    '36A',
                    '36G',
                    '40C-DDD',
                    '42C-DD',
                ]
            ]
        }
    },
    {
        name: 'Wireless',
        slug: 'ts_wireless',
        selectStores: false,
        collections: [
            {
                slug: 't_shirt',
                label: 'T-SHIRT BRA',
                isPrimary: true,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 10,
            },
            {
                slug: 'wireless',
                label: 'WIRELESS',
                order: 4,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 7,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_wireless/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_wireless/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_wireless/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/t_shirt_wireless/poster.jpg',
        },
        spec: [
            'Plunge neckline',
            'Lightly lined',
            'Fully adjustable straps',
            'Can be worn classic or crossback',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32A-DD',
                    '34A-DDD',
                    '36B-DDD,',
                ],[
                    '38C-DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32DDD-G',
                    '34G',
                    '36G',
                    '38B,',
                ],[
                    '38DDD',
                    '40C-DDD',
                    '42C-DD',
                    '44C-D',
                ]
            ]
        }
    },
    {
        name: 'The Forever \nBra Demi',
        sizedProductName: ['The Forever Bra Demi'],
        alternativeName: [
            'The Forever',
            'Bra Demi',
        ],
        slug: 'ts_forever-bra-demi',
        selectStores: false,
        collections: [
            {
                slug: 't_shirt',
                label: 'T-SHIRT BRA',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 5,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/the_forever_bra_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/the_forever_bra_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/the_forever_bra_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/the_forever_bra_demi/poster.jpg',
        },
        spec: [
            'Lightly Lined',
            '4 Layers of breathable, knitted support',
            'Pad is thoughtfully designed for a second life',
            'Adjustable straps convert to crossback',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '40C-DD',
                ]
            ]
        }
    },

    // sexy tee part 3
    {
        name: 'Demi',
        slug: 'st_demi',
        selectStores: false,
        collections: [
            {
                slug: 'sexy_tee',
                label: 'SEXY TEE',
                isPrimary: true,
            },
            {
                slug: 'demi',
                label: 'DEMI',
                order: 5,
            },
            {
                slug: 'lightly_lined',
                label: 'LIGHTLY LINED',
                order: 11,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 10,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_demi/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_demi/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_demi/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_demi/poster.jpg',
        },
        spec: [
            'Moderate-coverage cup',
            'Lightly lined',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32D',
                    '32DD (E)',
                    '32DDD (F)',

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38B',
                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '32D-DDD',
                    '34-38B-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32A-C',
                    '32-38G,',
                ],[
                    '34-36A',
                    '40C-DDD',
                    '42C-DD',
                    '44C-D',
                ]
            ]
        }
    },
    {
        name: 'Push-Up',
        slug: 'st_push_up',
        selectStores: false,
        collections: [
            {
                slug: 'sexy_tee',
                label: 'SEXY TEE',
                isPrimary: true,
            },
            {
                slug: 'push_up',
                label: 'PUSH-UP',
                order: 10,
            },
            // {
            //     slug: 'most_wanted',
            //     label: 'MOST WANTED',
            //     order: 9,
            // },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/sexy_t_push_up/poster.jpg',
        },
        spec: [
            'Soft push-up padding',
            'Plunge neckline',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '32A',
                    '32B',
                    '32C',
                    '32D',
                    '32DD (E)',

                    '34A',
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',

                    '38C',
                    '38D',
                    '38DD (E)',
                ]
            ],
            abbreviated: [
                [
                    '32A-DD',
                    '34A-DDD',
                    '36B-DD,',
                ],[
                    '38C-DD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '30B-DD',
                    '32DDD-G',
                    '34G,',
                ],[
                    '36A',
                    '36-38DDD',
                    '36G',
                    '40C-DDD',
                    '42C-DD',
                ]
            ]
        }
    },
    // {
    //     name: 'Plunge Bralette',
    //     slug: 'st_plunge_bralette',
    //     selectStores: true,
    //     collections: [
    //         {
    //             slug: 'sexy_tee',
    //             label: 'SEXY TEE',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'bra_top',
    //             label: 'BRA TOP',
    //             order: 5,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/plunge_bralette/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/plunge_bralette/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/plunge_bralette/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/plunge_bralette/poster.jpg',
    //     },
    //     spec: [
    //         'Double layer molded cotton',
    //         'Wireless',
    //         'V-neck silhouette',
    //         'Pull-on style',
    //         'Smooth back',
    //         'Plunge neckline',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 'XS',
    //                 'S',
    //                 'M',
    //                 'L',
    //                 'XL'
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 'XS',
    //                 'S',
    //                 'M',
    //                 'L',
    //                 'XL'
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [
    //             [
    //                 'XS',
    //                 'S',
    //                 'M',
    //                 'L',
    //                 'XL',
    //                 'XXL'
    //             ]
    //         ],
    //         moreOnline: []
    //     }
    // },
    // {
    //     name: 'Lacie Bralette',
    //     slug: 'st_lacie_bralette',
    //     selectStores: true,
    //     collections: [
    //         {
    //             slug: 'sexy_tee',
    //             label: 'SEXY TEE',
    //             isPrimary: true,
    //         },
    //         {
    //             slug: 'bra_top',
    //             label: 'BRA TOP',
    //             order: 4,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/lacie_bralette/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/lacie_bralette/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/lacie_bralette/bra.jpg',
    //         poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/t_shirt_sexy_t_bras/lacie_bralette/poster.jpg',
    //     },
    //     spec: [
    //         'Unlined',
    //         'Wireless',
    //         'Adjustable straps',
    //         'Pull-on style',
    //         'All-over stretch lace',
    //     ],
    //     sizes: {
    //         primary: [
    //             [
    //                 'XS',
    //                 'S',
    //                 'M',
    //                 'L',
    //                 'XL',
    //             ]
    //         ],
    //         abbreviated: [
    //             [
    //                 'XS-XL'
    //             ]
    //         ],
    //         selectStores: [],
    //         online: "XXS, XS, M, L, XL, XXL, Curvy XS, Curvy S, Curvy M, Curvy L, Curvy XL, Curvy XXL",
    //         moreOnline: []
    //     }
    // },

    // sport lounge
    {
        name: 'Knock Out \nFront Close',
        alternativeName: [
            'Front-Close ',
        ],
        slug: 'sl_knockout',
        selectStores: false,
        collections: [
            {
                slug: 'sport_lounge',
                label: 'KNOCKOUT',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 1,
                
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/knockout/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/knockout/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/knockout/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/knockout/poster.jpg',
        },
        spec: [
            'Maximum support',
            'Patented bra-within-a bra technology',
            'Structured-cups for compression',
            'Padded straps for stabilized support',
        ],
        sizes: {
            primary: [
                [
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '34B-DDD',
                    '36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32B-DDD',
                    '38B',
                    '40C-DDD',
                ]
            ]
        }
},
    // {
    //     name: 'High-Neck',
    //     alternativeName: [
    //         'Essential High-neck',
    //         'Sport Bra',
    //     ],
    //     slug: 'sl_high_neck',
    //     selectStores: false,
    //     collections: [
    //         {
    //             slug: 'sport_lounge',
    //             label: 'INCREDIBLE',
    //             isPrimary: true,
    //         },
    //     ],
    //     images: {
    //         front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/high_neck/front.jpg',
    //         back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/high_neck/back.jpg',
    //         bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/high_neck/bra.jpg',
    //     },
    //     spec: [
    //         'Wireless',
    //         'Pullover style',
    //         'Removable pads',
    //     ],
    //     sizes: {
    //     primary: [
    //     [
    //         'XS',
    //         'S',
    //         'SDD',
    //         'M',
    //         'MDD',
    //         'L',
    //         'LDD',
    //         'XL',
    //         '32A',
    //         '32B',
    //         '32C',
    //         '32D',
    //         '32DD (E)',
    //         '32DDD (F)',
    //         '34A',
    //         '34B',
    //         '34C',
    //         '34D',
    //         '34DD (E)',
    //         '34DDD (F)',
    //         '36B',
    //         '36C',
    //         '36D',
    //         '36DD (E)',
    //         '36DDD (F)',
    //         '38B',
    //         '38C',
    //         '38D',
    //         '38DD (E)',
    //         '38DDD (F)',
    //     ]
    // ],
    //         abbreviated: [
    //             [
    //             'XS-XL',
    //             ]
    //         ],
    //         selectStores: [],
    //         online: [],
    //         moreOnline: []
    //     }
    // },
    {
        name: 'Essential Strappy Sports Bra',
        sizedProductName: [
            'Strappy Sports Bra'
        ],
        alternativeName: [
            'Strappy',
            'SPORTS BRA',
        ],
        slug: 'sl_strappy_back',
        selectStores: false,
        collections: [
            {
                slug: 'sport_lounge',
                label: 'ESSENTIAL',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 2,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/strappy_back/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/strappy_back/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/strappy_back/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/strappy_back/poster.jpg',
        },
        spec: [
            'Minimum support',
            'Streamlined front',
            'Strappy back',
            'Bra pad inserts for shape',
        ],
        sizes: {
            primary: [
                [
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                ]
            ],
            abbreviated: [
                [
                    'XS, S, M, L, XL',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                ['XXL']
            ]
        }
    },
    {
        name: 'The Allegro \nMedium Sport Bra',
        sizedProductName: [
            'Medium Support Sport Bra'
        ],
        alternativeName: [
            'Medium Support',
            'Sport Bra'
        ],
        slug: 'sl_allegro',
        selectStores: false,
        collections: [
            {
                slug: 'sport_lounge',
                label: 'THE ALLEGRO',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 3,

            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/allegro/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/allegro/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/allegro/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/allegro/poster.jpg',
        },
        spec: [
            'Medium Support',
            'Pull-over style for easy on & off',
            'Wicking fabric keeps you cool and dry',
            'Removable padding',
            'Flexible crisscross straps & soft wide band',
            'Made for low-impact activities',
        ],
        sizes: {
            primary: [
                [
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36A',
                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '34-36B-DDD',
                    '38C-DDD'
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32A-DDD',
                    '34-36A'
                ]
            ]
        }
    },
    {
        name: 'Incredible Max Sport Bra',
        alternativeName: [
            'Max Sport Bra',
        ],
        slug: 'sl_max-sport',
        selectStores: false,
        collections: [
            {
                slug: 'sport_lounge',
                label: 'INCREDIBLE',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 4,
                
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/max_sport/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/max_sport/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/max_sport/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/incredible_freedom_bras/max_sport/poster.jpg',
        },
        spec: [
            'Maximum Support',
            'Moisture-wicking fabric keeps you cool and dry',
            'Molded one-piece pad for support, shape,',
            'and separation',
            'Pull-over style for easy on & off',
            'Adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '34B-DDD',
                    '36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    '32B-DDD',
                    '38B',
                    '40C-DDD'
                ]
            ],
        }
    },
    {
        name: 'Love Cloud \nSport Bra',
        sizedProductName: [
            'Sport bra'
        ],
        alternativeName: [
            'Sport bra'
        ],
        slug: 'sl_love-cloud-sport',
        selectStores: false,
        collections: [
            {
                slug: 'sport_lounge',
                label: 'LOVE CLOUD',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 5,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/love_cloud_sport/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/love_cloud_sport/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/love_cloud_sport/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/love_cloud_sport/poster.jpg',
        },
        spec: [
            'Minimum support',
            'Ultra-stretchy, soft foam pad',
            'Wireless for 24-hour comfort',
            'Moisture-wicking fabric',
            'Fully adjustable straps',
        ],
        sizes: {
            primary: [
                [
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                ]
            ],
            abbreviated: [
                [
                    'XS-XL'
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    'SDD',
                    'MDD',
                    'LDD',
                    'XXL'
                ]
            ],
        }
    },
    {
        name: 'The Featherweight Max Sports Bra',
        alternativeName: [
            'Max Sports Bra',
        ],
        slug: 'sl_featherweight-max-sport',
        selectStores: false,
        collections: [
            {
                slug: 'sport_lounge',
                label: 'THE FEATHERWEIGHT',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 6,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/featherweight_max_sport/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/featherweight_max_sport/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/featherweight_max_sport/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/featherweight_max_sport/poster.jpg',
        },
        spec: [
            'Injection mold pad technology & turbo dry liner',
            'Lasercut perforations allow targeted airflow to keep you cool and dry',
            'Provides a sleek look under clothes',
            'Fully adjustable, cushioned straps',
            'Made for high-impact activities',
        ],
        sizes: {
            primary: [
                [

                    '34B',
                    '34C',
                    '34D',
                    '34DD (E)',
                    '34DDD (F)',

                    '36B',
                    '36C',
                    '36D',
                    '36DD (E)',
                    '36DDD (F)',

                    '38C',
                    '38D',
                    '38DD (E)',
                    '38DDD (F)',
                ]
            ],
            abbreviated: [
                [
                    '34B-DDD',
                    '36B-DDD',
                    '38C-DDD',
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [

                    '32B-DDD',
                    '38B',
                    '40C-DDD'

                ]
            ],
        }
    },
    {
        name: 'The Player \nSports Bra',
        alternativeName: [
            'Sports Bra',
        ],
        slug: 'sl_the-player',
        selectStores: false,
        collections: [
            {
                slug: 'sport_lounge',
                label: 'THE PLAYER',
                isPrimary: true,
            },
            {
                slug: 'sport',
                label: 'SPORT',
                order: 7,
            },
        ],
        images: {
            front: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/the_player/front.jpg',
            back: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/the_player/back.jpg',
            bra: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/the_player/bra.jpg',
            poster: 'https://vs-bras-101.s3.eu-west-2.amazonaws.com/collections/sport_lounge_bras/the_player/poster.jpg',
        },
        spec: [
            'Maximum Support',
            'Wireless, 24-hour comfort',
            'Easy, pull-on racerback style',
            'Wide band hugs your torso',
            'Made for medium-impact activities',
        ],
        sizes: {
            primary: [
                [
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                ]
            ],
            abbreviated: [
                [
                    'XS-XL'
                ]
            ],
            selectStores: [],
            online: [],
            moreOnline: [
                [
                    'SDD',
                    'MDD',
                    'LDD',
                    'XXL',
                ]
            ],
        }
    },

]
