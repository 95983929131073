<template>
    <app-layout>
        <div class="h-full w-full flex flex-col items-center px-14 pb-14 overflow-auto" ref="page">
            <div class="flex flex-col items-center flex-shrink-0">
                <h1 class="text-7.5xl leading-7.5xl font-didot flex-shrink-0">BRA STYLES</h1>
                <p class="font-benton-regular text-2.5xl leading-4xl mt-6 flex-shrink-0">
                    Discover the silhouette that suits you.
                </p>
            </div>

            <div class="grid grid-cols-2 gap-x-7 gap-y-14 w-full font-benton mt-17 flex-shrink-0">
                <div class="slide-in flex-shrink-0"
                     v-for="(collection, index) in collections"
                     :key="collection.name"
                     :style="{'animation-delay': (1+index)*0.1+'s'}"
                >
                    <router-link class="flex flex-col items-center"
                                 :to="
                                 {
                                     name: 'products',
                                     params: {
                                         collection: collection.slug,
                                         category: 'bra_styles',
                                     },
                                 }"
                    >
                        <img class="w-full" :src="collection.image" />
                        <div class="flex flex-col items-center justify-center font-benton mt-6">
                            <h2 class="text-3.5xl leading-5xl font-benton-medium mb-1 text-center uppercase">{{collection.name}}</h2>
                            <p class="font-benton-regular text-2xl leading-9 text-center px-20" v-if="collection.description.length">
                                <template v-for="description in collection.description" :key="description">
                                    {{description}}<br>
                                </template>
                            </p>
                        </div>
                    </router-link>
                </div>
            </div>
            <button class="border-t border-navborder w-full mt-14 py-11 flex flex-col items-center justify-center flex-shrink-0"
                    @click="scrollToTop">
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.737 4.3024L2.18215 13.1332L0.440003 11.4455L10.6834 0.871699L21.56 11.4187L19.8714 13.16L10.737 4.3024Z" fill="black"/>
                </svg>
                <p class="mt-4 font-benton-medium text-2.5xl leading-7.1">Back to top</p>
            </button>
            <div class="w-full flex-shrink-0">
                <div class="border-t border-navborder flex items-center justify-center text-2.5xl leading-4xl pt-11 flex-shrink-0">
                    <p>Need some assistance? <span class="font-benton-medium">Ask an associate for help.</span></p>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import Collections from "@/router/collections.js";
import AppLayout from "@/Layout/AppLayout";
import _ from "lodash";

export default {
    name: "BraStylesPage",
    components: {AppLayout},
    data() {
        return {
            collections: _.filter(Collections, (_collection) => {
                return _collection.categories.includes('bra_styles');
            })
        }
    },

    methods: {
        scrollToTop() {
            this.$nextTick(() => {
                this.$refs.page.scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        }
    },
}
</script>
