<template>
    <div class="
        absolute top-24 left-0 right-0 z-50
        flex items-center gap-x-6 bg-pink-600 px-10 py-6
        hover:opacity-0
        "
    >
        <p class="text-3xl text-center text-white w-full">
            You are currently viewing the demo version of this app
        </p>
    </div>
</template>
