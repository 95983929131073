<template>
    <opacity-transition>
        <div v-if="show" class="fixed top-0 left-0 h-screen w-screen backdrop-filter bg-black bg-opacity-25 z-50 flex justify-center items-center">
            <div class="bg-white px-10 py-12 rounded-lg shadow flex flex-col items-center w-165 relative -mt-80 slide-in">
                <slot></slot>
                <button class="rounded-full bg-closegray flex items-center justify-center absolute -right-8 -top-8 w-16.5 h-16.5"
                        @click="$emit('close')"
                >
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2435 12.8318L19.9789 21.5671L21.5671 19.9789L12.8318 11.2435L21.5671 2.50817L19.9789 0.919927L11.2435 9.65528L2.50817 0.919922L0.919922 2.50817L9.65528 11.2435L0.919938 19.9789L2.50818 21.5671L11.2435 12.8318Z" fill="#221D1D"/>
                    </svg>
                </button>
            </div>
        </div>
    </opacity-transition>
</template>

<script>
import OpacityTransition from "@/components/transitions/OpacityTransition";
export default {
    name: "Modal",
    props: {
        show: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        OpacityTransition
    }
}
</script>
