<template>
    <div class="flex flex-col relative h-full">
        <breadcrumbs />
        <slot></slot>
        <floating-nav></floating-nav>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import FloatingNav from "@/components/FloatingNav";
export default {
    name: "AppLayout",
    components: {FloatingNav, Breadcrumbs},
}
</script>