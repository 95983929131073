
<template>
    <transition
        :enter-active-class="enterString"
        :leave-active-class="leaveString"
        enter-class="opacity-0 translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <slot/>
    </transition>
</template>

<script>
export default {
    computed: {
        enterString() {
            return 'transition-opacity ' + this.enterDuration + ' ' + this.enterDelay;
        },
        leaveString() {
            return 'transition-opacity ' + this.leaveDuration + ' ' + this.leaveDelay;
        }
    },
    props: {
        enterDuration: {
            type: String,
            default: 'duration-500',
        },
        leaveDuration: {
            type: String,
            default: 'duration-500',
        },
        enterDelay: {
            type: String,
            default: 'delay-0',
        },
        leaveDelay: {
            type: String,
            default: 'delay-0',
        }
    }
}
</script>
