<template>
<div class="h-full w-full flex flex-col">
    <div class="absolute z-1 bg-transparent w-full h-full" @click="exitSplashScreen"></div>
<!--    <transition-->
<!--        v-show="videoLoaded && false"-->
<!--        name="scale" appear-->
<!--    >-->
<!--        <video class="absolute bg-white top-0 left-0 flex-shrink-0 pointer-events-none"-->
<!--               src="https://vs-bras-101.s3.eu-west-2.amazonaws.com/videos/vs_bra_fit_sign.mp4"-->
<!--               preload="auto"-->
<!--               muted-->
<!--               autoplay-->
<!--               loop-->
<!--               playsinline-->
<!--               @loadeddata="handleLoaded"-->
<!--        />-->
<!--    </transition>-->
    <div v-if="showBackup" class="absolute z-1 w-full h-full bg-white">
        <img class="absolute top-0 left-0 flex-shrink-0 pointer-events-none opacity-30 video-transition"
             src="https://vs-bras-101.s3.eu-west-2.amazonaws.com/video_poster.jpg"
        />
    </div>
    <!-- TODO implement if video fails -->
    <transition name="scale" v-if="showBackup" appear>
        <div class="flex-shrink-0 z-10 h-full px-9 pb-11 mb-40 pt-52" >
            <div class="text-center h-full flex flex-col items-center justify-start">
                <p class="z-1 font-didot text-9xl leading-9xl mb-28 mt-2 transition-all duration-1000"
                   :class="{
                    'text-10xl scaling-text': showBackup,
                    'text-9xl': !showBackup,
                   }"
                >
                    What Do<br/>
                    You Want<br/>
                    Your<br/>
                    BRA<br/>
                    To Do For<br/>
                    You?
                </p>

                <router-link class="relative rounded-full w-96 h-96"
                             :to="{name: 'home'}"
                >
                    <button class="relative rounded-full bg-black text-white font-didot w-96 h-96 text-7xl leading-9.5 z-4">
                        START
                    </button>
                    <span class="left-0 top-0 animate-ping absolute inline-flex h-full w-full rounded-full bg-black opacity-75 -z-1"></span>
                </router-link>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
// TODO: set showBackup to false, uncomment video, remove video-transition from img
export default {
    name: "VideoPage",
    data() {
        return {
            videoLoaded: false,
            showBackup: true,
        }
    },
    methods: {
        exitSplashScreen() {
            this.$router.push({name: 'home'});
        },
        handleLoaded()  {
            // control showing of the video as safari is buggy with the poster image
            this.videoLoaded = true;
        }
    },
    mounted() {
        // if the video fails to load, show the backup
        setTimeout(() => {
            if (!this.videoLoaded) {
                this.showBackup = true;
            }
        }, 5000);
    }
}
</script>

<style scoped>
.slide-up-entrance-enter-active {
    transition: all 500ms cubic-bezier(0.155, 0.320, 0.560, 1.085);
    z-index: 50;
    transition-delay: 0.4s;
}

.slide-up-entrance-leave-active {
    transition: all 500ms 500ms cubic-bezier(0.155, 0.320, 0.560, 1.085);
}

.slide-up-entrance-leave-to {
    opacity: 0;
}

.slide-up-entrance-enter-from {
    transform: translateY(140%);
}

.scaling-text {
    -webkit-font-smoothing: antialiased;
    animation: scaling 14s ease-out infinite;
    will-change: auto;
}

.video-transition {
    animation: videoimg 14s ease-in-out infinite;
}

@keyframes scaling {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }

    5% {
        opacity: 0.2;
    }

    10% {
        opacity: 0.3;
    }

    22.5% {
        opacity: 1;
    }

    40% {
        opacity: 0.7;
    }

    50% {
        opacity: 0.3;
    }

    60% {
        opacity: 0;
    }

    75% {
        transform: scale(1.15);
        opacity: 0;
    }

    100% {
        transform: scale(0.9);
        opacity: 0;
    }
}

@keyframes videoimg {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
        //transform: scale(1.05);
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(1.15);
        //transform: scale(1);
    }
}

</style>
