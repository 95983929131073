<template>
    <component :is="renderer" />
</template>

<script>
import Collections from "@/router/collections";
import TheCollectionsPage from "@/pages/TheCollections/TheCollectionsPage";
import MostWantedBrasPage from "@/pages/MostWantedBras/MostWantedBrasPage";
import BraStylesPage from "@/pages/BraStyles/BraStylesPage";
import ShopBySizePage from "@/pages/ShopBySize/ShopBySizePage";

export default {
    name: "CollectionsPage",

    components: {
        TheCollectionsPage,
    },

    computed: {
        renderer() {
            switch(this.category) {
                case 'collections':
                    return TheCollectionsPage;
                case 'bra_styles':
                    return BraStylesPage;
                case 'shop_by_size':
                    return ShopBySizePage;
                case 'most_wanted':
                    return MostWantedBrasPage
                default:
                    return TheCollectionsPage;
            }
        },
    },

    data() {
        return {
            collections: Collections
        }
    },

    props: {
        category: {
            type: String,
            required: true,
        }
    }
}
</script>
