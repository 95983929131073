<template>
    <app-layout>
        <div class="flex flex-col flex-1 items-center justify-center">
            <div class="space-y-3 flex flex-col items-center justify-center mb-17 flex-shrink-0">
                <p class="font-benton-light text-4.5xl leading-5xl text-center flex-shrink-0">{{primaryCollection.label}}</p>
                <h2 class="font-benton-medium text-7.45xl leading-6.5xl px-20 uppercase text-center flex-shrink-0" v-if="activeProduct.alternativeName && activeProduct.alternativeName.length">
                    <template v-for="name in activeProduct.alternativeName" :key="name">
                        {{name}}<br>
                    </template>
                </h2>
                <h2 class="font-benton-medium text-7.45xl leading-6.5xl px-20 uppercase text-center flex-shrink-0" v-else>
                    {{activeProduct.name}}
                </h2>
            </div>

            <div class="flex flex-col items-center flex-1 flex-shrink-0">
                <div class="flex flex-col grid grid-cols-2 gap-x-2 flex-shrink-0">
                    <img class="bg-vsgray select-none slide-in flex-shrink-0"
                         :style="{'animation-delay': 1*0.1+'s'}"
                         :src="activeProduct.images.front"
                         :alt="'Photo of '+activeProduct.name+' bra by '+primaryCollection.label"
                    >
                    <img class="bg-vsgray select-none slide-in flex-shrink-0"
                         :style="{'animation-delay': 2*0.1+'s'}"
                         :src="activeProduct.images.back"
                         :alt="'Photo of '+activeProduct.name+' bra by '+primaryCollection.label"
                    >
                </div>
                <div class="flex flex-col space-y-10 w-160 mt-17 flex-shrink-0">
                    <div class="font-benton-medium text-2.5xl leading-4xl flex-shrink-0">
                        <p v-for="(description, index) in activeProduct.spec"
                           :key="index"
                        >
                            {{description}}
                        </p>
                    </div>
                    <div class="text-2.5xl leading-4xl font-benton-regular flex-shrink-0">
                        <template v-if="activeProduct.sizes.abbreviated.length">
                            <p v-if="Array.isArray(activeProduct.sizes.abbreviated)">
                                In-store sizes: <template v-for="(range, index) in activeProduct.sizes.abbreviated" :key="index">
                                {{arrayToString(range)}}
                                <template v-if="index !== activeProduct.sizes.abbreviated.length - 1">
                                    <br>
                                </template>
                            </template>
                            </p>
                            <p v-else class="whitespace-pre-wrap">In-store sizes: {{activeProduct.sizes.moreOnline}}</p>
                        </template>
                        <template v-if="activeProduct.sizes.selectStores.length">
                            <p v-if="Array.isArray(activeProduct.sizes.selectStores)">
                                Select stores sizes: <template v-for="(range, index) in activeProduct.sizes.selectStores" :key="index">
                                {{arrayToString(range)}}
                                <template v-if="index !== activeProduct.sizes.selectStores.length - 1">
                                    <br>
                                </template>
                            </template>
                            </p>
                            <p v-else class="whitespace-pre-wrap">Select stores sizes: {{activeProduct.sizes.moreOnline}}</p>
                        </template>
                        <template v-if="activeProduct.sizes.moreOnline.length">
                            <p v-if="Array.isArray(activeProduct.sizes.moreOnline)" class="whitespace-nowrap">
                                Additional online sizes: <template v-for="(range, index) in activeProduct.sizes.moreOnline" :key="index">
                                {{arrayToString(range)}}
                                <template v-if="index !== activeProduct.sizes.moreOnline.length - 1">
                                    <br>
                                </template>
                            </template>
                            </p>
                            <p v-else class="whitespace-pre-wrap">Additional online sizes: {{activeProduct.sizes.moreOnline}}</p>
                        </template>
                        <template v-if="activeProduct.sizes.online.length">
                            <p v-if="Array.isArray(activeProduct.sizes.online)">
                                Online sizes: <template v-for="(range, index) in activeProduct.sizes.online" :key="index">
                                {{arrayToString(range)}}
                                <template v-if="index !== activeProduct.sizes.online.length - 1">
                                    <br>
                                </template>
                            </template>
                            </p>
                            <p v-else class="whitespace-pre-wrap">Online sizes: {{activeProduct.sizes.online}}</p>
                        </template>
                        <template v-if="!!activeProduct.sizes.strapless && activeProduct.sizes.strapless.length">
                            <p>Strapless sizes: {{activeProduct.sizes.strapless}}</p>
                        </template>
                    </div>
                </div>
            </div>
            <div class="w-full px-14 flex-shrink-0">
                <div class="border-t-2 border-navborder flex items-center justify-center text-2.5xl leading-4xl py-11 flex-shrink-0">
                    <p>Not finding your size? <span class="font-benton-medium">Ask an associate for help.</span></p>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import Products from "@/router/products";
import _ from "lodash";
import AppLayout from "@/Layout/AppLayout";

export default {
    name: "ProductPage",
    components: {AppLayout},

    computed: {
        activeProduct() {
            return _.find(Products, (_product) => {
                return _product.slug === this.product;
            });
        },

        primaryCollection() {
            if(this.activeProduct.collections.length === 1) {
                return this.activeProduct.collections[0];
            }
            return _.find(this.activeProduct.collections, (_collection) => {
                return _collection.isPrimary;
            });
        }
    },

    methods: {
        arrayToString(list) {
            return list.join(', ');
        },
    },

    props: {
        product: {
            type: String,
            required: true,
        },
        collection: {
            type: String,
            required: false,
        },
        category: {
            type: String,
            required: true,
        },
    }
}
</script>
