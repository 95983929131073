import _ from 'lodash';
import {default as TheCollectionProducts} from "@/router/products";
import Collections from "@/router/collections";

export default [
    {
        label: 'Collections',
        href: {
            name: 'theCollections',
        },
        children: _.map(Collections, (_collection) => {
            return {
                label: _collection.name,
                href: {
                    name: 'products',
                    params: { collection: _collection.slug }
                },
                children: _.map(TheCollectionProducts[_collection.slug], (_product) => {
                    return {
                        label: _product.name,
                        href: {
                            name: 'product',
                            params: {
                                collection: _collection.slug,
                                product: _product.slug,
                            },
                        }
                    }
                })
            }
        }),
    }
];
