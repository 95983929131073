<template>
    <div class="rounded-full flex flex-col justify-center items-center fixed z-10 left-9 bottom-20 shadow-lg-left-offset p-2.5 space-y-1.5 bg-white">
        <router-link :to="{name: 'home'}">
            <div class="rounded-full bg-black h-24 w-24 flex items-center justify-center">
                <svg width="28" height="36" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 10.6842L12.5 1L1 10.6842V28.8421H24V10.6842Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.6837 16.7368H14.3153C14.9574 16.7368 15.5732 16.9919 16.0273 17.4459C16.4813 17.9 16.7364 18.5158 16.7364 19.1579V28.8421H8.2627V19.1579C8.2627 18.5158 8.51777 17.9 8.97181 17.4459C9.42584 16.9919 10.0416 16.7368 10.6837 16.7368V16.7368Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </router-link>
        <router-link :to="backUrl">
            <div class="rounded-full bg-black h-24 w-24 flex items-center justify-center">
                <svg width="16" height="26" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.93451 12.2989L13.9695 22.0203L12.0517 24L0.0359793 12.3598L12.0212 -1.7299e-07L14 1.91882L3.93451 12.2989Z" fill="white"/>
                </svg>
            </div>
        </router-link>
    </div>
</template>

<script>
import BreadcrumbsMixin from "@/components/BreadcrumbsMixin";

export default {
    name: "FloatingNav",

    mixins: [
        BreadcrumbsMixin,
    ],
}
</script>
