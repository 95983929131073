export default function getPageTransition(to, from) {
    if(from === 'splash') {
        return 'scale';
    }

    if(to === 'splash') {
        return 'scale';
    }

    if(to === 'home' && ['category', 'products', 'product', 'category.product'].includes(from)) {
        return 'slide-right';
    }

    if(to === 'category' && from === 'home') {
        return 'slide-left';
    }

    return 'fade';
}
