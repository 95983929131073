<template>
  <div class="relative h-full flex flex-col text-black bg-white">
      <router-view v-slot="{ Component, route}" class="bg-white">
          <transition :name="route.meta.transitionName" mode="out-in">
                <component :is="Component" />
          </transition>
      </router-view>
      <inactivity-notification @end="reset()" :exclude="['splash']"/>
      <AppBanner v-if="isStaging" />
  </div>
</template>

<script>
// import {ref} from "vue";
import InactivityNotification from "@/components/InactivityNotification";
import AppBanner from "@/components/AppBanner.vue";

let isStaging = process.env.NODE_ENV !== 'staging';
export default {
    name: 'App',
    components: {AppBanner, InactivityNotification},
    data() {
        return {
            isStaging,
        }
    },
    beforeMount() {
        this.isStaging = parseInt(process.env.VUE_APP_IS_STAGING);

        if (parseInt(process.env.VUE_APP_IS_STAGING)) {
            console.log('STAGING ENVIRONMENT -> LOAD MARKER.IO');

            window.markerConfig = {
                project: '64623f5e7af2d5d16b1d667b',
                source: 'snippet'
            };

            this.importMarkerIo();
        }
    },
    methods: {
        reset() {
            this.$router.push({
                name: 'splash',
            });
        },

        importMarkerIo() {
            !function(e,r){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
        }
    }
}
</script>
