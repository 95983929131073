<template>
    <app-layout>
        <component :is="renderer" :category="category" :collection="collection" />
    </app-layout>
</template>

<script>
import Products from "@/router/products";
import Categories from "@/router/categories";
import Collections from "@/router/collections";
import _ from 'lodash';
import AppLayout from "@/Layout/AppLayout";
import GenericProductsPage from "@/pages/Base/GenericProductsPage";

export default {
    name: "ProductsPage",
    components: {AppLayout},
    computed: {
        activeCollection() {
            return _.find(Collections, (_collection) => {
                return _collection.slug === this.collection;
            });
        },

        activeCategory() {
            return _.find(Categories, (_category) => {
                return _category.slug === this.category;
            });
        },

        products() {
            return _.filter(Products, (_product) => {
                return _.findIndex(_product.collections, (_collection) => {
                    return _collection.slug === this.collection;
                }) !== -1;
            });
        },

        tagline() {
            return this.activeCollection ? (this.activeCollection.description || null) : null;
        },

        renderer() {
            if(this.activeCategory.renderers && this.activeCategory.renderers.products) {
                return this.activeCategory.renderers.products;
            }

            return GenericProductsPage;
        },
    },

    props: {
        collection: {
            type: String,
            required: true,
        },
        category: {
            type: String,
            required: true,
        }
    }
}
</script>
