<template>
    <div class="h-full w-full flex flex-col items-center p-16">
        <div class="text-center flex-shrink-0 mt-8 flex-shrink-0">
            <h1 class="text-7.5xl leading-7.5xl font-didot">FIND THE BRA THAT'S<br>RIGHT FOR YOU</h1>
        </div>

        <div class="mt-0 flex flex-col space-y-6 items-center justify-center text-6xl font-benton flex-1 flex-shrink-0">
            <router-link class="bg-vsgray flex grid grid-cols-2 slide-in flex-shrink-0"
                         v-for="(category, index) in categories"
                         :key="category.slug"
                         :to="{name: 'category', params: {category: category.slug}}"
                         :style="{'animation-delay': (1+index)*0.1+'s'}"
            >
                <img :src="category.image" alt="The Collections poster" />
                <div class="flex flex-col items-center justify-center space-y-7 p-20 relative">
                    <p class="font-didot text-4.5xl leading-5xl text-center uppercase">{{ category.name }}</p>
                    <button class="bg-white py-5 px-14 text-base tracking-tighter font-benton-medium">BROWSE</button>
                    <div class="absolute right-6 bottom-6">
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08477 8.79674L0.26095 2.18617L1.56509 0.839966L9.73577 8.75531L1.5858 17.16L0.240234 15.8552L7.08477 8.79674Z" fill="#221D1D"/>
                        </svg>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="w-full flex-shrink-0">
            <router-link class="flex items-center font-benton-medium flex-shrink-0" :to="{name: 'splash'}">
                <div class="mr-3">
                    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.30253 11.2626L13.1333 19.8174L11.4456 21.5596L0.87182 11.3162L11.4188 0.439569L13.1602 2.12813L4.30253 11.2626Z" fill="#221D1D"/>
                    </svg>
                </div>
                <p class="text-3xl">Exit</p>
            </router-link>
        </div>
    </div>
</template>

<script>
import Categories from "@/router/categories";
export default {
    name: "HomePage",

    data() {
        return {
            categories: Categories,
        };
    }
}
</script>
