import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "@/pages/HomePage";
import CollectionPage from "@/pages/CollectionPage";
// import {default as TheCollectionsProductsPage} from "@/pages/TheCollections/ProducstPage";
// import {default as MostWantedBrasProductsPage} from "@/pages/MostWantedBras/ProducstPage";

import ProductsPage from "@/pages/ProducstPage";
import ProductPage from "@/pages/ProductPage";
import getPageTransition from "@/page-transitions";
import VideoPage from "@/pages/VideoPage";
import SizePage from "@/pages/ShopBySize/SizePage";
import ProductCupSizesPage from "@/pages/ShopBySize/ProductsPage";


const routes = [
  {
    path: '/',
    name: 'splash',
    component: VideoPage
  },

  {
    path: '/home',
    name: 'home',
    component: HomePage
  },

  {
    path: '/:category',
    component: CollectionPage,
    name: 'category',
    props: true,
  },
  // {
  //   path: '/:category/product/:product',
  //   component: ProductPage,
  //   name: 'category.product',
  //   props: true,
  // },

  {
    path: '/:category/:collection/',
    component: ProductsPage,
    name: 'products',
    props: true,
    meta: {
      label: 'Products'
    }
  },
  {
    path: '/:category/:collection/:product',
    component: ProductPage,
    name: 'product',
    props: true,
  },

  {
    path: '/:category/:size',
    component: SizePage,
    name: 'size',
    props: true
  },

  {
    path: '/:category/:size/:cupSize',
    component: ProductCupSizesPage,
    name: 'cupSize',
    props: true
  },

  {
    path: '/:category/:size/:cupSize/:product',
    component: ProductPage,
    name: 'sizedProduct',
    props: true
  },
]

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

router.afterEach((to, from) => {
  to.meta.transitionName = getPageTransition(to.name, from.name)
})

export default router
