<template>
    <modal :show="showWarning" @close="resetTimer">
        <div class="flex flex-col items-center border-b pb-9 mb-6">
            <p class="font-benton-medium text-3.5xl leading-5xl mb-8">
                Would you like to continue browsing?
            </p>
            <button
                class="bg-black text-white font-benton-medium text-base leading-6 px-14 py-3.4"
                @click="resetTimer"
            >
                CONTINUE
            </button>
        </div>
        <p class="font-benton text-2xl leading-10">Exiting in <span class="font-benton-medium">{{timeLeft}}</span> seconds</p>
    </modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
    name: "InactivityNotification",
    data() {
        return {
            timeLeft: 15,
            idleTime: null,
            countdown: null,
            showWarning: false,
        }
    },

    mounted() {
        this.registerListeners();
    },

    methods: {
        startCountdown() {
            this.countdown = setInterval(() => {
                this.timeLeft = --this.timeLeft;
                if(this.timeLeft === 0) {
                    this.clearTimers();
                    this.$emit('end');
                }
            }, 1000);
        },

        startIdleTimer() {
            this.idleTime = setTimeout(() => {
                this.showWarning = true;
                this.startCountdown();
            }, 40000);
        },

        /**
         * Resets the idle timer
         */
        resetTimer() {
            this.showWarning = false;
            this.clearTimers();
            this.startIdleTimer();
        },

        clearTimers() {
            this.timeLeft = 15;
            clearInterval(this.countdown);
            clearTimeout(this.idleTime);
        },

        stopListeners() {
            window.removeEventListener('touchstart', this.resetTimer);
            window.removeEventListener('touchmove', this.resetTimer);
            window.removeEventListener('touchend', this.resetTimer);
            window.removeEventListener('mousedown', this.resetTimer);
            window.removeEventListener('keypress', this.resetTimer);
        },

        registerListeners() {
            window.addEventListener('touchstart', this.resetTimer);
            window.addEventListener('touchmove', this.resetTimer);
            window.addEventListener('touchend', this.resetTimer);
            window.addEventListener('mousedown', this.resetTimer);
            window.addEventListener('keypress', this.resetTimer);
            this.startIdleTimer();
        }
    },

    components: {
        Modal,
    },

    props: {
        exclude: {
            type: Array,
            default() {
                return [];
            },
        }
    },

    watch: {
        '$route': function (to) {
            this.showWarning = false;
            this.stopListeners();
            this.clearTimers();

            if(!this.exclude.includes(to.name)) {
                this.registerListeners();
            }
        }
    }
}
</script>
